<template>
    <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>

                <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>

                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Lista zamówień</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

        <!-- SEARCHER -->
        <div class="min-w-0 flex-1 xl:col-span-6">
            <div :class="{'flex items-center justify-between': true}">
                <div :class="{'w-full flex': true, 'justify-between': true, 'flex-col space-y-4 lg:space-y-0 lg:flex-row': this.showStatusFilters}">
                    <Transition name="fade">
                    <div v-if="!this.showStatusFilters" class="w-2/3 sm:w-1/3 flex">
                        <label for="search" class="sr-only">Szukaj</label>
                        <div class="relative">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                        </div>
                        <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                    </div>
                    </Transition>
                    <Transition name="fade">
                    <div v-if="showStatusFilters === true" class="mr-4 flex flex-col lg:flex-row w-full justify-between lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none">
                        <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                        <div class="flex items-center">
                            <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null}">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data utworzenia</label>
                                <input :disabled="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" type="date" @change="pickDateOfCreate($event)" v-model="pickedDateOfCreate" ref="pickedCreatedDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                            </div>
                            <Transition name="fade2">
                            <div v-if="pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null" class="ml-2 mr-2">
                                <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                            </div>
                            </Transition>
                        </div>
                        <div class="flex items-center">
                            <div :class="{'relative w-full rounded-md border border-gray-300 lg:w-44 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null}">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data planowanej dostawy</label>
                                <input :disabled="pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null" type="date" @change="pickDateOfDelivery($event)" v-model="pickedDateOfDelivery" ref="suggestedDeliveryDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                            </div>
                            <Transition name="fade2">
                            <div v-if="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" class="ml-2 mr-2">
                                <span @click="resetDateOfDelivery" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                            </div>
                            </Transition>
                        </div>
                        <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Kraj</label>
                            <select @change="changeSort()" v-model="countryName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                <option value="all">Wszystkie</option>
                                <option v-for="(country, index) in countries" :key="index" :value="country.name">{{country.displayName}}</option>
                            </select>
                        </div>
                        </div>
                        <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                        <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                            <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                            <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                <option v-if="!hideOptions" value="creationAsc">Utworzono: Od najstarszych</option>
                                <option v-if="!hideOptions" value="creationDesc">Utworzono: Od najnowszych</option>
                                <option value="suggestedDeliveryAsc">P. dostawa: Od najwcześniejszych</option>
                                <option value="suggestedDeliveryDesc">P. dostawa: Od najpóźniejszych</option>
                            </select>
                        </div>
                        <button type="button" @click="this.pickStatus()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Zastosuj</button>
                        </div>
                    </div>
                    </Transition>
                    <div v-if="searcher.found.length === 0" :class="{'flex justify-between items-end lg:items-center lg:justify-start transition-all duration-1000 w-auto': true, 'min-w-filters': showStatusFilters}">
                        <div v-tooltip="`Wyszukiwarka AMAZON`" v-if="!showStatusFilters" class=" mt-1 mr-4 cursor-pointer flex justify-center items-center flex-col select-none">
                            <Switch v-model="accurateSearch" :class="[accurateSearch ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2']">
                                <span class="sr-only">Use setting</span>
                                <span aria-hidden="true" :class="[accurateSearch ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
                            </Switch>
                            <span :class="[accurateSearch ? 'text-xs text-indigo-600 mt-0.5' : 'text-xs text-gray-400 mt-0.5', 'font-medium']">Wyszukiwarka ID</span>
                        </div>
                        <div @click="showFilters()" class="mr-4 mt-1 cursor-pointer flex justify-center items-center flex-col select-none">
                            <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                            <span class="text-xs mt-1 font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                        </div>

                        <div v-if="showStatusFilters" :class="{'flex lg:hidden items-center justify-center': true}">
                            <router-link to="/dashboard/orders/add" type="button"  class="transition ease-in-out duration-300 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                    Wynik wyszukiwania dla frazy: "{{accurateSearch ? searcher.phrase :  searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                    <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>          
            </div>
        </div>

        <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 lg:px-0">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 sm:pl-6 text-left text-xs font-semibold text-gray-600 uppercase">Kraj</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Kod pocztowy</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Nazwa mebla</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Producent</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Status</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">Planowana dostawa</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase">
                                    <span class="sr-only">Edycja</span>
                                </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="order in searcher.found.length > 0 ? searcher.found : orders" :key="order.id">
                                    <td class="whitespace-nowrap px-3 py-4 pl-4 sm:pl-6 text-sm font-medium text-gray-900">
                                        <div class="flex items-center">
                                            <img v-if="order.countryFlagURL.length>0" :src="order.countryFlagURL" v-tooltip="`${order.delivery.country.code}`" class="h-4 w-6 border border-gray-300">
                                            <span v-if="order.countryFlagURL.length === 0 && order.delivery.countryCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 font-semibold">
                                        <div class="flex items-center">
                                            <span v-if="order.delivery.postCode.length>0">{{ order.delivery.postCode }}</span>
                                            <span v-if="order.delivery.postCode.length === 0 && order.delivery.postCode.length === 0">--</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                        <div class="flex items-center">
                                            <span @click.prevent="openCommentsModal(order)" v-tooltip="`Zobacz komentarze wewnętrzne`" :class="[order.statusId === 400 && createCommentList(order)[0].length > 0 ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800', 'mr-1 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer']" v-for="prod in createProductList(order).slice(0,1)" :key="prod">{{prod}}</span>
                                            <span @click.prevent="openCommentsModal(order)" class="cursor-pointer" v-tooltip="`${createProductList(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="createProductList(order).length - 1 > 0">+ {{createProductList(order).length - 1}} więcej</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                        <span class="mr-1 inline-flex items-center rounded-full py-0.5 text-xs font-medium" v-for="manu in getManufacturer(order).slice(0,1)" :key="manu">{{typeof manu === 'object' ? manu.name : manu}}</span>
                                        <span v-tooltip="`${getManufacturer(order).slice(1).toString().replaceAll(',',' | ')}`" v-if="getManufacturer(order).length - 1 > 0">+ {{getManufacturer(order).length - 1}} więcej</span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                      <span v-tooltip="`Status: ${getStatusById(order.statusId).name} - Informacje: ${getStatusById(order.statusId).info}`" :class="{'cursor-pointer inline-flex w-full justify-center items-center rounded-md px-2 py-1 text-xs font-medium': true, 'bg-yellow-400 text-white': order.statusId === 1, 'bg-yellow-600 text-yellow-100': order.statusId === 2, 'bg-blue-500 text-blue-100': order.statusId === 3, 'bg-purple-400 text-purple-100': order.statusId === 4, 'bg-pink-500 text-pink-100': order.statusId === 5, 'bg-green-500 text-green-100': order.statusId === 6, 'bg-purple-500 text-purple-100': order.statusId === 7, 'bg-pink-800 text-pink-100': order.statusId === 400, 'bg-orange-400 text-orange-100': order.statusId === 401, 'bg-teal-500 text-teal-100': order.statusId === 402, 'bg-gray-400 text-gray-100': order.statusId === 403, 'bg-gray-500 text-gray-100': order.statusId === 404, 'bg-red-600 text-red-100': order.statusId === 300, 'bg-gray-300 text-gray-100': order.statusId === 8}">
                                        {{order.statusId === 2 ? 'Kompletowanie' : getStatusById(order.statusId).name}}
                                      </span>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs">
                                        <span v-if="order.delivery.date.suggested !== null">{{order.delivery.date.suggested.asText}}</span>
                                        <span v-if="order.delivery.date.suggested === null" class="opacity-60">Nie wskazano</span>
                                    </td>
                                    <td class="relative flex justify-end whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <button @click="toggleModalProcessOrder(order)" class="transition ease-in-out duration-300 text-blue-600 hover:text-blue-900">
                                            <div class="transition ease-in-out duration-300 relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">Przetwórz</div>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                       
                    </div>
                </div>
            </div>
        </div>
        <EmptyState class="mt-2 mb-2" v-if="orders.length === 0"></EmptyState>
        <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
            <button type="button" @click.prevent="loadMoreOrders" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
        </div>
        <CommentsModal v-if="commentsModal.toggle" :order="commentsModal.data" @close="closeCommentsModal"></CommentsModal>
        <ModalProcessOrder v-if="modalProcessOrder.open" :order="modalProcessOrder.order" @close="toggleModalProcessOrder"/>
    </div>
</template>

<script>
    import {db} from "@/firebase/gfbconf.js";
    import axios from 'axios';
    import moment from 'moment';
    import EmptyState from '../components/EmptyState.vue';
    import { SearchIcon, PlusSmIcon, FilterIcon } from '@heroicons/vue/outline';
    import { HomeIcon  } from '@heroicons/vue/solid';
    import pdfMake from "pdfmake/build/pdfmake";
    import { Switch } from '@headlessui/vue'
    import pdfFonts from "pdfmake/build/vfs_fonts";
    import CommentsModal from "@/components/CommentsInOrder.vue";
    import ModalProcessOrder from '../components/ModalProcessOrder.vue';

    pdfMake.vfsimport = pdfFonts.pdfMake.vfs;
    export default {
        name: "OrdersList",
        data()
        {
            return {
                modalProcessOrder: {
                    open: false
                },
                hideOptions: false,
                accurateSearch: false,
                query: null,
                 sortName: "creationDesc",
                sortType: {
                    order: "desc",
                    type: "meta.created.date"
                },
                pickedDateOfCreate: null,
                pickedDateOfCreateUnix: null,
                pickedDateOfDelivery: null,
                pickedDateOfDeliveryUnix: null,
                showStatusFilters: false,
                countriesData: [],
                statuses:[],
                loading: false,
                searcher: {
                    found: [],
                    locked: false,
                    phrase: "",
                    lastCall: null
                },
                orders: [],
                queryLimit: 25,
                dbListener: null,
                lastLoaded: null,
                queryLimitReached: false,
                commentsModal:{
                    toggle: false,
                    data: null
                },
                selectedStatus: false,
                countryName: 'all',
                countries: [
                    {
                        displayName: 'Polska',
                        name: 'Poland',
                        code: 'PL',
                        flagUrl: 'https://flagcdn.com/w320/pl.png',
                    },
                    {
                        displayName: 'Niemcy',
                        name: 'Germany',
                        code: 'DE',
                        flagUrl: 'https://flagcdn.com/w320/de.png',
                    },
                    {
                        displayName: 'Francja',
                        name: 'France',
                        code: 'FR',
                        flagUrl: 'https://flagcdn.com/w320/fr.png',
                    },
                    {
                        displayName: 'Luksemburg',
                        name: 'Luxembourg',
                        code: 'LU',
                        flagUrl: 'https://flagcdn.com/w320/lu.png',
                    },
                    {
                        displayName: 'Włochy',
                        name: 'Italy',
                        code: 'IT',
                        flagUrl: 'https://flagcdn.com/w320/it.png',
                    },
                    {
                        displayName: 'Holandia',
                        name: 'Netherlands',
                        code: 'NL',
                        flagUrl: 'https://flagcdn.com/w320/nl.png',
                    },
                                        {
                        displayName: 'Belgia',
                        name: 'Belgium',
                        code: 'BE',
                        flagUrl: 'https://flagcdn.com/w320/be.png',
                    },
                    {
                        displayName: 'Austria',
                        name: 'Austria',
                        code: 'AT',
                        flagUrl: 'https://flagcdn.com/w320/at.png',
                    },
                ],
            }
        },
        components: { SearchIcon, HomeIcon, PlusSmIcon, CommentsModal, EmptyState, Switch, FilterIcon, ModalProcessOrder },
        async created()
        {
            if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.storage) {
                this.$router.push("/hub")
            }
            await this.getStatuses()
            await this.getCountries()
            await this.getAllOrders()
        },
        methods:
        {
             toggleModalProcessOrder(order) {
                if(!this.modalProcessOrder.open) {
                    this.modalProcessOrder.open = true
                    this.modalProcessOrder.order = order
                } else {
                    this.modalProcessOrder.open = false
                }
            },
            openCommentsModal(order){
                this.commentsModal.toggle = true
                this.commentsModal.data = order
            },
           
            closeCommentsModal(){
                this.commentsModal.toggle = false
                this.commentsModal.data = null
            },
            getManufacturer(order)
            {
                const manufacturer = Object.entries(order.positions)
                let translatedListOfManufacturer = [];
                manufacturer.forEach(element => {
                    
                    if(element[0])
                    {
                        if(typeof element[1].product.manufacturer === "string") {
                            if(translatedListOfManufacturer.includes(element[1].product.manufacturer) === false)
                            {
                                translatedListOfManufacturer.push(element[1].product.manufacturer);
                            }
                        } else {
                            if(translatedListOfManufacturer.includes(element[1].product.manufacturer.name) === false)
                            {
                                translatedListOfManufacturer.push(element[1].product.manufacturer.name);
                            }
                        }
                    }
                })

                return translatedListOfManufacturer
            },
            createCommentList(order)
            {
                const products = Object.entries(order.positions)
                let translatedListOfComments = [];
                products.forEach(element => {
                    if(element[0])
                    {
                        translatedListOfComments.push(element[1].infos.onOrderCreation);
                    }
                })

                return translatedListOfComments
            },
            createProductList(order)
            {
                const products = Object.entries(order.positions)
                let translatedListOfProducts = [];
                products.forEach(element => {
                    if(element[0])
                    {
                        translatedListOfProducts.push(element[1].product.name);
                    }
                })

                return translatedListOfProducts
            },
            pickDateOfDelivery(event){
                this.hideOptions = true;
                if(this.hideOptions)
                {
                    this.sortName = 'suggestedDeliveryAsc';
                }
                let mDate = moment(event.target.value)
                if(!mDate.isValid())
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Popraw datę!",
                        subheader: `Wykryto, że data jest niepoprawna.`,
                        success: false
                    });
                    this.pickedDateOfDelivery = null;
                    this.pickedDateOfDeliveryUnix = null
                }
                else
                {
                    this.pickedDateOfDeliveryUnix = mDate.unix()
                }
            },
            pickDateOfCreate(event){
                let mDate = moment(event.target.value)
                if(!mDate.isValid())
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Popraw datę!",
                        subheader: `Wykryto, że data jest niepoprawna.`,
                        success: false
                    });
                    this.pickedDateOfCreate = null;
                    this.pickedDateOfCreateUnix = null
                }
                else
                {
                    this.pickedDateOfCreateUnix = mDate.unix()
                }
            },
            resetDateOfCreate(){
                this.hideOptions = false;
                this.pickedDateOfCreate = null;
                this.pickedDateOfCreateUnix = null
            },
            resetDateOfDelivery(){
                this.hideOptions = false;
                this.pickedDateOfDelivery = null;
                this.pickedDateOfDeliveryUnix = null
            },
            changeSort(){
                if(this.sortName === "creationAsc"){
                    this.sortType = {
                        order: "asc",
                        type: "meta.created.unix"
                    }
                }
                if(this.sortName === "creationDesc"){
                    this.sortType = {
                        order: "desc",
                        type: "meta.created.unix"
                    }
                }
                if(this.sortName === "suggestedDeliveryAsc"){
                    this.sortType = {
                        order: "asc",
                        type: "delivery.date.suggested.unix"
                    }
                }
                if(this.sortName === "suggestedDeliveryDesc"){
                    this.sortType = {
                        order: "desc",
                        type: "delivery.date.suggested.unix"
                    }
                }
            },

            async getAllOrders()
            {
                this.resetSearch()
                this.hideOptions = false,
                this.query = null;
                this.queryLimit = 25;
                if(this.dbListener !== null){
                    this.dbListener()
                    this.dbListener = null
                }
                this.query = db.collection("Orders").where('statusId', "==", 2)

                if(this.countryName !== 'all')
                {
                    this.query = this.query.where("delivery.country.name", "==", this.countryName)
                }

                if(this.pickedDateOfDeliveryUnix !== null){
                    this.pickedDateOfDeliveryUnix = parseInt(this.pickedDateOfDeliveryUnix)
                    let startOfDayDelivery = this.pickedDateOfDeliveryUnix
                    let endOfDayDelivery = this.pickedDateOfDeliveryUnix+ 86399
                    this.query = this.query.where('delivery.date.suggested.unix', ">=", startOfDayDelivery).where('delivery.date.suggested.unix', "<=",  endOfDayDelivery)
                    this.hideOptions = true;
                }

                if(this.pickedDateOfCreateUnix !== null){
                    this.pickedDateOfCreateUnix = parseInt(this.pickedDateOfCreateUnix)
                    let startOfDay = this.pickedDateOfCreateUnix
                    let endOfDay = this.pickedDateOfCreateUnix + 86399
                    this.query = this.query.where('meta.created.unix', ">=", startOfDay).where('meta.created.unix', "<=", endOfDay)
                }

                if(this.pickedDateOfDeliveryUnix !== null && this.pickedDateOfCreateUnix === null){
                    this.query = this.query.orderBy(`delivery.date.suggested.unix`, `${this.sortType.order}`)
                    this.hideOptions = true;
                }
                if(this.pickedDateOfDeliveryUnix === null && this.pickedDateOfCreateUnix !== null){
                    this.query = this.query.orderBy(`meta.created.unix`, `${this.sortType.order}`)
                }
                if(this.pickedDateOfDeliveryUnix === null && this.pickedDateOfCreateUnix === null){
                    this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)
                }

                this.dbListener = this.query.limit(this.queryLimit)
                .onSnapshot((querySnapshot) => {
                this.orders = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    let current = doc.data()
                    current.countryFlagURL = "";
                        if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                        {
                            current.delivery.country.code = current.invoice.countryCode;
                        }
                       
                        for(let y=0; y< this.countriesData.length; y++)
                        {
                            if(this.countriesData[y].code === current.delivery.country.code)
                            {
                                current.countryFlagURL = this.countriesData[y].flagURL;
                            }
                        }
                        this.orders.push(current);
                    this.lastLoaded = current.meta.created.date;
                });
            });
            },
            showFilters()
            {
                this.showStatusFilters = !this.showStatusFilters
                if(!this.showStatusFilters){
                    this.pickedDateOfCreate = null;
                    this.pickedDateOfCreateUnix = null
                    this.pickedDateOfDelivery = null;
                    this.pickedDateOfDeliveryUnix = null

                    this.sortName = "creationDesc",
                    this.sortType = {
                        order: "desc",
                        type: "meta.created.date"
                    }
                    this.pickStatus()
                }
            },
            pickStatus(){
                this.dbListener()
                this.getAllOrders()
            },
            getStatusById(id){
                let currentStatus = {
                    name: "Brak",
                    info: "Brak",
                    id: null
                }
                this.statuses.forEach((status) => {
                    if(status.id === id){
                        currentStatus = {
                            name: status.name,
                            info: status.info,
                            id: status.id
                        }
                    }
                })
                return currentStatus
            },
            async getStatuses(){
                let res = await db.collection("OrderStatuses").doc("StatusList").get()
                this.statuses = res.data().list
            },
            totalPrice(positions, currency, deliveryPrice)
            {
                let totalPrice = 0;
                for (const pos in positions) {
                    totalPrice = totalPrice + (positions[pos].product.quantity * positions[pos].product.pricePerItemTaxIncluded)
                }
                if(deliveryPrice > 0)
                {
                    totalPrice = totalPrice + deliveryPrice;
                }
                return `${totalPrice} ${currency}`
            },
            async getCountries()
            {
                this.countriesData = [];
                const res = await axios.get(`${this.$store.state.apiLink}/data/getCountries`)
                if(res.data.status === 'SUCCESS')
                {
                    res.data.countries.forEach(country => {
                        this.countriesData.push({
                            code: country.countryCode, flagURL: country.flagURL
                        });
                    });
                }
            },
            resetSearch()
            {
                this.searcher.found = [];
                this.searcher.lastCall = null;
                this.searcher.phrase = "";
                this.searcher.locked = false
            },
            async search()
            {
                if(this.accurateSearch)
                {
                    if(this.searcher.phrase.length > 0)
                    {
                        this.searcher.locked = true;
                        const res = await db.collection("Orders").where("origin.meta.external.ids.externalOrderId", "==", this.searcher.phrase).get()
                        if(!res.empty)
                        {
                            let allFound = []
                            res.docs.forEach((found) => {
                            let current = found.data();
                            current.countryFlagURL = "";
                                if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                                {
                                    current.delivery.country.code = current.invoice.countryCode;
                                }
                                for(let y=0; y< this.countriesData.length; y++)
                                {
                                    if(this.countriesData[y].code === current.delivery.country.code)
                                    {
                                        current.countryFlagURL = this.countriesData[y].flagURL;
                                    }
                                }
                                allFound.push(current);
                            });
                            if(res.docs.length > 0)
                            {
                                this.searcher.found = allFound;
                                this.dbListener();
                                this.$store.commit('setNotification',{
                                    show: true,
                                    head: "Wyszukiwanie zakończone!",
                                    subheader: `Znaleziono ${res.docs.length} zamówień.`,
                                    success: true
                                });
   
                            }else{
                                this.$store.commit('setNotification',{
                                    show: true,
                                    head: "Nie znaleziono wyników!",
                                    subheader: `Spróbuj wyszukać inną frazę.`,
                                    success: false
                                });
                            }
   
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Wyszukiwanie zakończone niepowodzeniem!",
                                subheader: `Coś poszło nie tak...`,
                                success: false
                            });
                        }
                        setTimeout(()=>
                        {
                            this.searcher.locked = false;
                        }, 3000)
                    }
                    else
                    {
                        return;
                    }
                }
                else
                {
                    if(this.searcher.phrase.length > 0)
                    {
                        this.searcher.locked = true;
                        const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                            index: "jan-art-orders",
                            collection: "Orders",
                            phrase: this.searcher.phrase,
                            searchFields: ['client.name', 'client.company', 'delivery.name', 'delivery.company', 'id', 'invoice.fullname', 'delivery.postCode', 'externalId'],
                            archive: false
                        })
                        if(res.data.success)
                        {
                            let allFound = []
                            res.data.found.forEach((found) => {
                            let current = found
                            current.countryFlagURL = "";
                                if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                                {
                                    current.delivery.country.code = current.invoice.countryCode;
                                }
                                for(let y=0; y< this.countriesData.length; y++)
                                {
                                    if(this.countriesData[y].code === current.delivery.country.code)
                                    {
                                        current.countryFlagURL = this.countriesData[y].flagURL;
                                    }
                                }
                                allFound.push(current);
                            });
                            this.searcher.found = allFound;
                            if(res.data.found.length > 0)
                            {
                                this.searcher.lastCall = res.data.data;
                                this.dbListener();
                                this.$store.commit('setNotification',{
                                    show: true,
                                    head: "Wyszukiwanie zakończone!",
                                    subheader: `Znaleziono ${res.data.found.length} zamówień.`,
                                    success: true
                                });
   
                            }else{
                                this.$store.commit('setNotification',{
                                    show: true,
                                    head: "Nie znaleziono wyników!",
                                    subheader: `Spróbuj wyszukać inną frazę.`,
                                    success: false
                                });
                            }
   
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "Wyszukiwanie zakończone niepowodzeniem!",
                                subheader: `Coś poszło nie tak...`,
                                success: false
                            });
                        }
                        setTimeout(()=>
                        {
                            this.searcher.locked = false;
                        }, 3000)
                    }
                    else
                    {
                        return;
                    }
                }
            },
            continueListening()
            {
                this.queryLimit = this.queryLimit + 25;
                this.dbListener = this.query.limit(this.queryLimit)
                .onSnapshot((querySnapshot) => {
                    this.orders = [];
                    if(querySnapshot.docs.length<this.queryLimit)
                    {
                        this.queryLimitReached = true;
                    }
                    else
                    {
                        this.queryLimitReached = false;
                    }
                    querySnapshot.forEach((doc) => {
                        let current = doc.data()
                        current.countryFlagURL = "";
                        if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                        {
                            current.delivery.country.code = current.invoice.countryCode;
                        }
                       
                        for(let y=0; y< this.countriesData.length; y++)
                        {
                            if(this.countriesData[y].code === current.delivery.country.code)
                            {
                                current.countryFlagURL = this.countriesData[y].flagURL;
                            }
                        }
                        this.orders.push(current);
                        this.lastLoaded = current.meta.created.date;
                    });
                });
            },
            loadMoreOrders()
            {
                this.dbListener();
                this.continueListening();      
            },
        },
        computed:
        {
        },
        beforeUnmount() {
            if(this.dbListener !== null){
                this.dbListener();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .fade-enter-active{
    transition: opacity 1s ease;
    }
    .fade-leave-active{
        transition: opacity 0s;
        display: none;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .fade2-enter-active,
    .fade2-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade2-enter-from,
    .fade2-leave-to {
    opacity: 0;
    }
</style>